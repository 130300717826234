<template>
	<el-container id="container" style="height: 4259px;" direction="vertical">
		<div class="block">
			<img src="../../../assets/image/cooperatebg.png" alt="" style="width: 1920px; height: 480px;">
			<div class="text">壹本账全国合作伙伴</div>
			<div class="text1">火热招募中</div>
			<div class="text2">管理账，下一个即将爆发的千亿级市场！</div>
			<div class="button" @mouseenter="buttonEnter(0)" @mouseleave="buttonLeave(0)">{{buttontext0}}</div>
		</div>
		<div class="block2">
			<div v-if="bgflag">
				<img src="../../../assets/image/cooperatebg2.png" alt="" style="width: 1920px; height: 720px;">
			</div>
			<div v-else>
				<img src="../../../assets/image/cooperatebg3.png" alt="" style="width: 1920px; height: 720px;" >
			</div>
			<el-menu
			  default-active="1"
			  class="el-menu-demo"
			  mode="horizontal"
			  @select="handleSelect"
			  background-color="#FFFFFF"
			  text-color="#020202"
			  active-text-color="#FF0000">
				<el-menu-item index="1">我是财税机构</el-menu-item>
				<el-menu-item index="2">我是软件经销商 </el-menu-item>
			</el-menu>
			<div class="box1" v-if="bgflag">
				<div class="text3">
					我是财税机构 >>>
				</div>
				<div class="text4">
					正在面临以下难题：
				</div>
				<div class="text4" style="display: flex; align-items: center; position: absolute; left: 362px; top: 377px;">
					<img src="../../../assets/image/true7.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">外账越来越便宜，没法做了，好迷茫怎么办？</div>
				</div>
				<div class="text4" style="display: flex; align-items: center; position: absolute; left: 362px; top: 417px;">
					<img src="../../../assets/image/true7.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">内账有市场吗？怎么做？不会做怎么办？</div>
				</div>
				<div class="text4" style="display: flex; align-items: center; position: absolute; left: 362px; top: 457px;">
					<img src="../../../assets/image/true7.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">管理账、经营账、经营管理账、财务外包……没有合适的工具，不敢接啊！</div>
				</div>
				<div class="text4" style="display: flex; align-items: center; position: absolute; left: 362px; top: 497px;">
					<img src="../../../assets/image/true7.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">做了好几年内账外包，几乎不赚钱？</div>
				</div>
			</div>
			
			<div class="box1" v-else>
				<div class="text3" style="left: 1075px;">
					我是软件经销商  >>>
				</div>
				<div class="text4" style="left: 1076px;">
					正在面临以下难题：
				</div>
				<div class="text4" style="display: flex; align-items: center; position: absolute; left: 1075px; top: 377px;">
					<img src="../../../assets/image/true7.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">传统软件市场，一片红海，竞争激烈。</div>
				</div>
				<div class="text4" style="display: flex; align-items: center; position: absolute; left: 1075px; top: 417px;">
					<img src="../../../assets/image/true7.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">客户越来越难搞，不断被抢走？</div>
				</div>
				<div class="text4" style="display: flex; align-items: center; position: absolute; left: 1075px; top: 457px;">
					<img src="../../../assets/image/true7.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">没有新产品，业绩停滞，一直在吃老本？</div>
				</div>
				<div class="text4" style="display: flex; align-items: center; position: absolute; left: 1075px; top: 497px;">
					<img src="../../../assets/image/true7.png" style="width: 30px; height: 26px;">
					<div style="margin-left: 9px;">找不到新项目，业绩看不到增量？</div>
				</div>
			</div>
		</div>
		<div class="block3">
			<div v-if="bgflag2==1">
				<img src="../../../assets/image/cooperatebg4.png" alt="" style="width: 1920px; height: 994px;">
				<div style="width: 0px; height: 0px; border-style: solid; border-color: #0F2E4A transparent transparent  transparent; position: absolute; top: 423px; left: 197px; border-width: 12px 0px 0px 13px;">
				</div>
				<div class="text7">
					财务管理水平，决定一个企业的生死存亡。
					<br>但是，90%中小企业都是“糊涂账”，经营数据分散割裂、杂乱无章。老板决策靠“拍脑袋”，缺乏及时、有效的管理账支撑。
					<br>截止到2022年底，我国登记在册的市场主体高达1.69亿户。
					<br>——后疫情时代，越来越多的老板趋向理性决策，希望通过数据管理加强竞争力、向内部管理要效益。
				</div>
				<div class="text7" style="top: 557px;">
					2018-2022年全国市场主体总量（亿户）
				</div>
				<div class="text7" style="width: 398px;height: 1px;background: #FFFFFF;top: 800px;">
				</div>
				<div style="width: 24px;height: 86px;background: #FFFFFF;opacity: 0.71; z-index: 1001; position: absolute; top: 715px; left: 514px;">
					<div class="text8" style="margin-top: -36px;">
						1.1
					</div>
				</div>
				<div style="width: 24px;height: 100px;background: #FFFFFF;opacity: 0.71; z-index: 1001; position: absolute; top: 701px; left: 586px;">
					<div class="text8" style="margin-top: -36px;margin-left: -4px;">
						1.23
					</div>
				</div>
				<div style="width: 24px;height: 118px;background: #FFFFFF;opacity: 0.71; z-index: 1001; position: absolute; top: 683px; left: 658px;">
					<div class="text8" style="margin-top: -36px;">
						1.4
					</div>
				</div>
				<div style="width: 24px;height: 144px;background: #FFFFFF;opacity: 0.71; z-index: 1001; position: absolute; top: 657px; left: 730px;">
					<div class="text8" style="margin-top: -36px;margin-left: -4px;">
						1.54
					</div>
				</div>
				<div style="width: 24px;height: 164px;background: #FFBC6C;opacity: 0.71; z-index: 1001; position: absolute; top: 637px; left: 802px;">
					<div class="text8" style="margin-top: -36px;margin-left: -4px;">
						1.69
					</div>
				</div>
				<div class="text9">
					<div class="text10">2018年</div>
					<div class="text10">2019年</div>
					<div class="text10">2020年</div>
					<div class="text10">2021年</div>
					<div class="text10">2022年</div>
				</div>
			</div>
			<div v-else-if="bgflag2==2">
				<img src="../../../assets/image/cooperatebg5.png" alt="" style="width: 1920px; height: 994px;" >
				<div style="width: 0px; height: 0px; border-style: solid; border-color: #4A3215 transparent transparent  transparent; position: absolute; top: 518px; left: 197px; border-width: 12px 0px 0px 13px;">
				</div>
				<div class="text7" style="top: 372px;">
					假设10%中小企业需要管理账，那么潜在客户大约是1690万户。 
					<br>假设每户收费3万元/年，那么每年的市场规模大约为5000多亿元。
				</div>
				<div class="text7" style="top: 472px; color: #FECB00 !important;">
					*相比之下，代理记账每年的市场规模大约为1000多亿元。
				</div>
				<div class="text11">
					10<br>
					<div style="font-size: 24px; font-family:AlibabaPuHuiTi_2_55_Regular;font-weight: normal;color: #FFFFFF;line-height: 26px;">%</div>
				</div>
				<div class="text11" style="left: 733px;">
					1690<br>
					<div style="font-size: 24px; font-family:AlibabaPuHuiTi_2_55_Regular;font-weight: normal;color: #FFFFFF;line-height: 26px;">万户</div>
				</div>
				<div class="text11" style="left: 982px;">
					5000<br>
					<div style="font-size: 24px; font-family:AlibabaPuHuiTi_2_55_Regular;font-weight: normal;color: #FFFFFF;line-height: 26px;">亿元</div>
				</div>
			</div>
			<div v-else-if="bgflag2==3">
				<img src="../../../assets/image/cooperatebg6.png" alt="" style="width: 1920px; height: 994px;" >
				<div style="width: 0px; height: 0px; border-style: solid; border-color: #0F4E89 transparent transparent  transparent; position: absolute; top: 614px; left: 197px; border-width: 12px 0px 0px 13px;">
				</div>
				<div class="text7" style="top: 325px;">
					问题，就是机会。
					<br>管理账的市场机会，在哪儿？
				</div>
				
				<div class="box1" style=" top: 438px; left: 460px;"></div>
				<div class="box2" style=" top: 438px; left: 460px;">
					<div class="text12" style="top: 38px; left: 174px;">
						01.数据分散
					</div>
					<div class="text13" style="top: 74px; left: 31px;">
						<li>基础管理薄弱，各环节的经营数据分散、杂乱、割裂。</li>
						<li>业务和财务脱节，财务数据不及时、不完整、不准确，
						<br>无法支撑老板的经营决策。</li>
					</div>
				</div>
				
				<div class="box1" style=" top: 438px; left: 1201px;"></div>
				<div class="box2" style=" top: 438px; left: 1201px;">
					<div class="text12" style="top: 38px; left: 174px;">
						02.人员瓶颈
					</div>
					<div class="text13" style="top: 74px; left: 31px;">
						<li>往往请不起专业的财务人员，只能自己简单的记个流水账。</li>
						<li>专业的财务人员，常常陷入“专业思维”，不会做老板想
						<br>要的管理账。</li>
					</div>
				</div>
				
				<div class="box1" style=" top: 653px; left: 460px;"></div>
				<div class="box2" style=" top: 653px; left: 460px;">
					<div class="text12" style="top: 22px; left: 174px;">
						03.专业局限
					</div>
					<div class="text13" style="top: 55px; left: 31px;">
						<li>及时性差：财务软件，一般都是月度结账，老板无法
						<br>及时看到数据。</li>
						<li>可理解性差：财务软件，报表上都是专业术语，绝大
						<br>多数老板看不懂。</li>
					</div>
				</div>
				
				<div class="box1" style=" top: 653px; left: 1201px;"></div>
				<div class="box2" style=" top: 653px; left: 1201px;">
					<div class="text12" style="top: 38px; left: 174px;">
						04.工具矛盾
					</div>
					<div class="text13" style="top: 74px; left: 31px;">
						<li>财务软件（金蝶、用友等），只能由专业会计使用。</li>
						<li>市场上，没有一款可以让不懂财务的人员使用的软件。</li>
					</div>
				</div>
				<div  class="box4"></div>
				<div class="box3">
					<div style="height: 42px;">
						<span class="text14" style="margin-right: 6px;">4</span>大问题
					</div>
					<div>
						制约中小企业发展
					</div>
				</div>
				
				<img src="../../../assets/image/line.png" alt="" style="width: 83px; height: 38px; position: absolute; top: 508px; left: 920px;">
				<img src="../../../assets/image/line.png" alt="" style="width: 83px; height: 38px; position: absolute; top: 712px; left: 920px; transform: rotateX(180deg);">
				<img src="../../../assets/image/line.png" alt="" style="width: 83px; height: 38px; position: absolute; top: 508px; left: 1117px; transform: rotateY(180deg)">
				<img src="../../../assets/image/line.png" alt="" style="width: 83px; height: 38px; position: absolute; top: 712px; left: 1117px; transform:rotate(180deg);">
			</div>
			<div class="text5">
				千亿级蓝海市场<span v-html="'\u00a0\u00a0'"/></span>蓄势待发
			</div>
			<div class="text6">
				先人一步<span v-html="'\u00a0\u00a0'"/></span>抢抓黄金新赛道
			</div>
			<div class="menubox" id="menu">
				<el-menu
				  default-active="1"
				  class="el-menu-vertical-demo"
				  @select="handleSelect2"
				  text-color="#FFFFFF"
				  active-text-color="#FFFFFF">
					<el-menu-item index="1" class="changeborder">大市场</el-menu-item>
					<el-menu-item index="2" class="changeborder">大收益 </el-menu-item>
					<el-menu-item index="3" class="changeborder">大机会 </el-menu-item>
				</el-menu>
			</div>
		</div>
		<div class="block4">
			<div class="text15">
				壹本账，实时生成老板看得懂的管理报表！
			</div>
			<div class="text16" style="top: 198px; left: 299px;">
				<img src="../../../assets/image/true8.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">小白也能做账，门槛低</div>
			</div>
			<div class="text16" style="top: 198px; left: 646px;">
				<img src="../../../assets/image/true8.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">不依赖专业会计，成本低</div>
			</div>
			<div class="text16" style="top: 198px; left: 1015px;">
				<img src="../../../assets/image/true8.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">实时生成，随时能看</div>
			</div>
			<div class="text16" style="top: 198px; left: 1333px;">
				<img src="../../../assets/image/true8.png" style="width: 30px; height: 26px;">
				<div style="margin-left: 9px;">没有专业术语，一看就懂</div>
			</div>
			<div class="block4button" @mouseenter="buttonEnter(1)" @mouseleave="buttonLeave(1)">
				{{buttontext1}}
			</div>
		</div>
		<div class="block5">
			<div class="text17">
				做管理账，有哪些商业模式？
			</div>
			<div class="text18">
				携手壹本账<span v-html="'\u00a0\u00a0'"/></span>共拓千亿市场
			</div>
			
			<div class="block5box" style="left: 210px;">
				<div class="boxtop" style="width: 480px;height: 100px;border-radius: 20px 20px 0px 0px;">
					<div class="text19">01</div>
					<div class="text20">财务外包</div>
					<div  style="width: 97px; height: 89px; position: absolute; top: 11px; left: 360px; overflow: hidden">
						<img src="../../../assets/image/cooperate1.png">
					</div>
				</div>
				<div class="line">
				</div>
				<div style="width: 480px;height: 334px;background: #FFFFFF;border-radius: 0px 0px 20px 20px; z-index: 1001;">
					<div class="text21">
						市场上，也称之为“财务外包”、“内账外包”、
						<br>“经营账外包”等。
					</div>
					<div class="text22">
						<li><b>客户</b>：负责提供业务单据。</li>
						<li><b>服务商</b>：负责录入业务单据、出具管理报表。 </li>
					</div>
					<div class="text23">
						起步期，可以采取“1+1”模式；扩张期，可以
						<br>升级为“1+2”、“1+3”模式……
						<br>人效：人均12-15户。
					</div>
				</div>
			</div>
			
			<div class="block5box" style="left: 720px;">
				<div class="boxtop"  style="width: 480px;height: 100px;border-radius: 20px 20px 0px 0px;">
					<div class="text19">02</div>
					<div class="text20">顾问指导</div>
					<div  style="width: 97px; height: 89px; position: absolute; top: 11px; left: 360px; overflow: hidden">
						<img src="../../../assets/image/cooperate2.png">
					</div>
				</div>
				<div class="line">
				</div>
				<div style="width: 480px;height: 334px;background: #FFFFFF;border-radius: 0px 0px 20px 20px; z-index: 1001;">
					<div class="text21">
						适合客户自己有录单人员的情形，如：出纳、
						<br>仓管或文员。（Tips：不是专业会计） 
					</div>
					<div class="text22">
						<li><b>客户</b>：负责录入业务单据。</li>
						<li><b>服务商</b>：负责指导客户录单、出具管理报表。 </li>
					</div>
					<div class="text23">
						人效：人均能突破30户。
					</div>
				</div>
			</div>
			
			<div class="block5box" style="left: 1230px;">
				<div class="boxtop"  style="width: 480px;height: 100px;border-radius: 20px 20px 0px 0px;">
					<div class="text19">03</div>
					<div class="text20">卖软件</div>
					<div  style="width: 97px; height: 89px; position: absolute; top: 11px; left: 360px; overflow: hidden">
						<img src="../../../assets/image/cooperate3.png">
					</div>
				</div>
				<div class="line">
				</div>
				<div style="width: 480px;height: 334px;background: #FFFFFF;border-radius: 0px 0px 20px 20px; z-index: 1001;">
					<div class="text21">
						适合客户自己有录单人员的情形，且十分介意
						<br>内部数据的安全性和保密性。 
					</div>
					<div class="text22">
						<li><b>客户</b>：负责录入业务单据、出具管理报表。</li>
						<li><b>服务商</b>：负责软件安装和培训。 </li>
					</div>
					<div class="text23">
						
					</div>
				</div>
			</div>
		
			<div class="block5button" @mouseenter="buttonEnter(2)" @mouseleave="buttonLeave(2)">
				{{buttontext2}}
			</div>
		</div>
		
		<div class="block6">
			<div class="text24">
				他们和你一样，最终都选择了壹本账！
			</div>
			<div style="width: 1500px; height: 371px; background: #FFFFFF; box-shadow: 0px 8px 46px 8px rgba(54,54,54,0.06); 
			border-radius: 20px;position: absolute; left: 210px; top: 215px;">
				<el-carousel height="488px" loop arrow="never">
					<el-carousel-item>
						<div style="position: absolute; top: 37px; left: 545px;">
							<img src="../../../assets/image/shangyinhao.png" alt="">
						</div>
						<div style="position: absolute; top: 301px; left: 1382px;">
							<img src="../../../assets/image/xiayinhao.png" alt="">
						</div>
						<div style="width: 106px;height: 106px;background: #FFFFFF;box-shadow: 7px 4px 14px 2px rgba(54,54,54,0.14);
						border-radius: 50%; position: absolute; left: 90px; top: 133px;">
							<img src="../../../assets/image/logo1.png" alt="">
						</div>
						<div style="width: 106px;height: 106px;background: #FFFFFF;box-shadow: 7px 4px 14px 2px rgba(54,54,54,0.14);
						border-radius: 50%; position: absolute; left: 210px; top: 133px;">
							<img src="../../../assets/image/person1.png" alt="">
						</div>
						<div class="text25">
							杨雪
						</div>
						<div class="text26">
							深圳明昊财税管理服务有限公司
							<br>董事长
						</div>
						<div class="text27">
							<b>前期灵活性很强</b>。在不增加额外成本的前提下，能快速突破落地，然后再深化细耕，
							<br>优化流程，建立体系。软件操作简单，入手快，专业度要求不高。
							<br>PC端同步，能<b>实时、通俗易懂</b>地把报表展现在老板面前，让数据说话，解决了客户过
							<br>去决策“拍脑袋”和“经验式”的问题。
							<br>用业务单据解决财务专业问题，真正实现<b>两账合一、业财融合</b>。孙总独创的JIT（准时
							<br>制成本核算）不仅解决了生产型企业成本核算难题，还能监管企业的物料进销存情况。
							<br>针对多架构组织、项目管理以及订单管理、款项计划，壹本账绝对是666！
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div style="position: absolute; top: 37px; left: 545px;">
							<img src="../../../assets/image/shangyinhao.png" alt="">
						</div>
						<div style="position: absolute; top: 301px; left: 1382px;">
							<img src="../../../assets/image/xiayinhao.png" alt="">
						</div>
						<div style="width: 106px;height: 106px;background: #FFFFFF;box-shadow: 7px 4px 14px 2px rgba(54,54,54,0.14);
						border-radius: 50%; position: absolute; left: 90px; top: 133px;">
							<img src="../../../assets/image/logo2.png" alt="">
						</div>
						<div style="width: 106px;height: 106px;background: #FFFFFF;box-shadow: 7px 4px 14px 2px rgba(54,54,54,0.14);
						border-radius: 50%; position: absolute; left: 210px; top: 133px;">
							<img src="../../../assets/image/person2.png" alt="">
						</div>
						<div class="text25">
							张洪钦
						</div>
						<div class="text26">
							广州优业财务咨询有限公司
							<br>董事长
						</div>
						<div class="text27" style="top: 127px;">
							自从用了壹本账系统做自己企业账务，在每天录单的情况下，能够及时了解公司盈亏
							<br>情况，同时更能了解哪个业务做得好？哪个员工的业绩比较领先？这样方便管理者及
							<br>时调整关键指标，做出相应的激励措施。
						</div>
						<div class="text27" style="top: 246px;">
							<b>让我们管理有工具，决策有数据依据！</b>感谢壹本账创始人孙同献老师的壹本账系统。
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div style="position: absolute; top: 37px; left: 545px;">
							<img src="../../../assets/image/shangyinhao.png" alt="">
						</div>
						<div style="position: absolute; top: 301px; left: 1382px;">
							<img src="../../../assets/image/xiayinhao.png" alt="">
						</div>
						<div style="width: 106px;height: 106px;background: #FFFFFF;box-shadow: 7px 4px 14px 2px rgba(54,54,54,0.14);
						border-radius: 50%; position: absolute; left: 90px; top: 133px;">
							<img src="../../../assets/image/logo3.png" alt="">
						</div>
						<div style="width: 106px;height: 106px;background: #FFFFFF;box-shadow: 7px 4px 14px 2px rgba(54,54,54,0.14);
						border-radius: 50%; position: absolute; left: 210px; top: 133px;">
							<img src="../../../assets/image/person3.png" alt="">
						</div>
						<div class="text25">
							刘怡利
						</div>
						<div class="text26">
							东莞市企牧财税企业管理有限公司
							<br>董事长
						</div>
						<div class="text27" style="top: 153px;">
							电脑端＋手机小程序，数据实时同步，适用不同的场合和时间！<b>数据及时清晰！</b>
						</div>
						<div class="text27" style="top: 209px;">
							壹本账系统易懂易操作，不同数据不同分析，高效便捷！
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div style="position: absolute; top: 37px; left: 545px;">
							<img src="../../../assets/image/shangyinhao.png" alt="">
						</div>
						<div style="position: absolute; top: 301px; left: 1382px;">
							<img src="../../../assets/image/xiayinhao.png" alt="">
						</div>
						<div style="width: 106px;height: 106px;background: #FFFFFF;box-shadow: 7px 4px 14px 2px rgba(54,54,54,0.14);
						border-radius: 50%; position: absolute; left: 90px; top: 133px;">
							<img src="../../../assets/image/logo4.png" alt="">
						</div>
						<div style="width: 106px;height: 106px;background: #FFFFFF;box-shadow: 7px 4px 14px 2px rgba(54,54,54,0.14);
						border-radius: 50%; position: absolute; left: 210px; top: 133px;">
							<img src="../../../assets/image/person4.png" alt="">
						</div>
						<div class="text25">
							李霞
						</div>
						<div class="text26">
							重庆麦积企业服务集团
							<br>董事长
						</div>
						<div class="text27" style="top: 127px;">
							壹本账系统在我们开发深度服务的过程中，赢得了客户的一致好评：<b>操作简单、</b>
							<br><b>功能强大！</b>
						</div>
						<div class="text27" style="top: 209px;">
							一键生成老板看得懂的报表，为老板的决策提供支持和依据，是客户经营管理的
							<br>有效利器！
						</div>
					</el-carousel-item>
					<el-carousel-item>
						<div style="position: absolute; top: 37px; left: 545px;">
							<img src="../../../assets/image/shangyinhao.png" alt="">
						</div>
						<div style="position: absolute; top: 301px; left: 1382px;">
							<img src="../../../assets/image/xiayinhao.png" alt="">
						</div>
						<div style="width: 106px;height: 106px;background: #FFFFFF;box-shadow: 7px 4px 14px 2px rgba(54,54,54,0.14);
						border-radius: 50%; position: absolute; left: 90px; top: 133px;">
							<img src="../../../assets/image/logo5.png" alt="">
						</div>
						<div style="width: 106px;height: 106px;background: #FFFFFF;box-shadow: 7px 4px 14px 2px rgba(54,54,54,0.14);
						border-radius: 50%; position: absolute; left: 210px; top: 133px;">
							<img src="../../../assets/image/person5.png" alt="">
						</div>
						<div class="text25">
							张治彬
						</div>
						<div class="text26">
							成都金博士财税服务有限公司
							<br>技术总监
						</div>
						<div class="text27" style="top: 82px;">
							公司壹本账，经营更健康！
						</div>
						<div class="text27" style="top: 133px;">
							孙同献先生所著的《可复制的管理账模式-财税机构开拓管理账业务的方法论》一书，
							<br>有效助推了我司<b>壹本账咨询</b>和<b>管理账外包</b>两项新产品的落地！
						</div>
						<div class="text27" style="top: 218px;">
							通过采用“工具＋方法＋实战”三位一体的落地模式，我司以“壹本账管理系统”为
							<br>工具，结合“对内合规，对外合法”的财税规划，做真正有价值于客户的服务，助力
							<br>企业数字化经营。
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</el-container>
</template>

<script>
  export default {
    name: "cooperate",
		data() {
			return {
				buttontext0: "我要合作",
				buttontext1: "我要合作",
				buttontext2: "我要合作",
				height: 3000,
				flag1: false,
				flag2: false,
				flag3: false,
				flag4: false,
				bgflag: true,
				bgflag2: 1
			}
		},
		methods:{
			buttonEnter(index) {
				this.$set(this, `buttontext${index}`, "合作专线：13807323918（孙老师）");
			},
			buttonLeave(index) {
				this.$set(this, `buttontext${index}`, "我要合作");
			},
			
			handleSelect(key, keyPath) {
				if (key == 1) {
					this.bgflag = true;
				} else {
					this.bgflag = false;
				}
			},
			
			handleSelect2(key, keyPath) {
				this.bgflag2 = key;
				if (key == 1) {
					var element2 = document.getElementById("menu");
					element2.style.boxShadow = '0px 8px 46px 8px rgba(54,54,54,0.5)';
					var element = document.getElementById("menu");
					var element = document.getElementsByClassName("changeborder");
					element[0].style.borderBottomColor = '#1F3F5D';
					element[1].style.borderBottomColor = '#1F3F5D';
					element[2].style.borderBottomColor = '#1F3F5D';
					element[0].style.borderTopColor = '#1F3F5D';
					element[1].style.borderTopColor = '#1F3F5D';
					element[2].style.borderTopColor = '#1F3F5D';
				} else if (key == 2) {
					var element2 = document.getElementById("menu");
					element2.style.boxShadow = '0px 8px 46px 8px rgba(104,73,38,0.79)';
					var element = document.getElementsByClassName("changeborder");
					element[0].style.borderBottomColor = '#684926';
					element[1].style.borderBottomColor = '#684926';
					element[2].style.borderBottomColor = '#684926';
					element[0].style.borderTopColor = '#684926';
					element[1].style.borderTopColor = '#684926';
					element[2].style.borderTopColor = '#684926';
				} else if (key == 3) {
					var element2 = document.getElementById("menu");
					element2.style.boxShadow = '0px 8px 46px 8px #1D1E29';
					var element = document.getElementsByClassName("changeborder");
					element[0].style.borderBottomColor = '#1F3F5D';
					element[1].style.borderBottomColor = '#1F3F5D';
					element[2].style.borderBottomColor = '#1F3F5D';
					element[0].style.borderTopColor = '#1F3F5D';
					element[1].style.borderTopColor = '#1F3F5D';
					element[2].style.borderTopColor = '#1F3F5D';
				}
			}
		}
  }
</script>

<style lang="less" scoped>
	.el-container {
		width: 1920px;
		
		.block {
			width: 1920px;
			height: 480px;
			position: absolute;
			
			.text {
				font-size: 60px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FFFFFF;
				position: absolute;
				left: 702px;
				top: 102px;
			}
			
			.text1 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FFFFFF;
				position: absolute;
				left: 843px;
				top: 177px;
			}
			
			.text2 {
				font-size: 30px;
				font-family: Alibaba PuHuiTi 2.0;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				left: 706px;
				top: 254px;
			}
			
			.button {
				width: 217px;
				height: 62px;
				background: #FFFFFF;
				box-shadow: 4px 2px 3px 0px rgba(199,24,0,0.23);
				border-radius: 31px;
				position: absolute;
				left: 852px;
				top: 321px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FF0000;
			}
			
			.button:hover {
				width: 450px;
				height: 62px;
				background: linear-gradient(0deg, #D50000 0%, #FF5400 100%);
				box-shadow: 4px 2px 7px 1px #DEE0E7;
				border-radius: 30px;
				left: 735px;
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FFFFFF;
			}
		}
		
		.block2 {
			width: 1920px;
			height: 720px;
			position: absolute;
			top: 580px;
			
			.el-menu {
				width: 1500px;
				height: 143px;
				position: absolute;
				left: 210px;
				top: 0px;
				display: flex;
				justify-content: space-around;
				align-items: center;
				background: none !important;
				
				.el-menu-item {
					width: 334px;
					height: 143px;
					display: flex;
					justify-content: center;
					align-items: flex-end;
					font-size: 28px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #2A2A2A;
					line-height: 58px;
					border-bottom: 11px solid #FF0000;
					background: none !important;
					margin-bottom: -16px;
				}
				
				.el-menu-item.is-active {
					font-size: 36px;
					font-family: AlibabaPuHuiTi_2_55_Regular;
					font-weight: normal;
					color: #000000 !important;
					line-height: 36px;
					padding: 0 0 14px 0;
					margin-bottom: -10px;
				}
			}
			
			.box1 {
				.text3 {
					font-size: 30px;
					font-family: AlibabaPuHuiTi_2_55_Regular;
					font-weight: normal;
					color: #000000;
					line-height: 48px;
					position: absolute;
					top: 282px;
					left: 362px;
				}
				
				.text4 {
					font-size: 20px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: 500;
					color: #000000;
					line-height: 48px;
					position: absolute;
					top: 335px;
					left: 363px;
				}
			}
		}
	
		.block3 {
			width: 1920px;
			height: 994px;
			position: absolute;
			top: 1300px;
			
			.text5 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 48px;
				position: absolute;
				top: 116px;
				left: 696px;
			}
			
			.text6 {
				font-size: 30px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				top: 185px;
				left: 796px;
			}
			
			.text7 {
				font-size: 22px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF !important;
				line-height: 48px;
				position: absolute;
				top: 329px;
				left: 465px;
				opacity: 0.8 !important;
				z-index: 1001;
			}
			
			.text8 {
				font-size: 16px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				text-align: center;
				opacity: 0.8 !important;
				z-index: 1001;
			}
			
			.text9 {
				width: 360px;
				height: 56px;
				font-size: 16px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 56px;
				display: flex;
				justify-content: center;
				align-content: space-around;
				position: absolute;
				top: 800px;
				left: 490px;
				
				.text10 {
					width: 72px; 
					height: 56px; 
					line-height: 56px;
					text-align: center;
				} 
			}
			
			.text11 {
				width: 165px;
				height: 165px;
				background: #684926; 
				border-radius: 50%; 
				position: absolute; 
				top: 571px; 
				left: 484px;
				font-size: 55px;
				font-family: AlibabaPuHuiTi_2_105_Heavy;
				font-weight: 600;
				color: #FFFFFF;
				line-height: 72px;
				display: flex;
				flex-flow: column;
				justify-content: center;
				align-items: center;
				z-index: 1001;
			}
			
			.box1 {
				width: 458px;
				height: 180px;
				background: #8498AB;
				border: 1px solid #88ABDA;
				opacity: 0.3;
				border-radius: 10px;
				position: absolute;
			}
			
			.box2 {
				width: 458px;
				height: 180px;
				position: absolute;
				z-index: 1001;
				
				.text12 {
					font-size: 20px;
					font-family: AlibabaPuHuiTi_2_75_SemiBold;
					font-weight: normal;
					color: #FFFFFF;
					line-height: 24px;
					position: absolute;
					opacity: 0.9 !important;
					z-index: 1001;
				}
				
				.text13 {
					font-size: 15px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #FFFFFF;
					line-height: 26px;
					position: absolute;
					opacity: 0.9 !important;
					z-index: 1001;
				}
			}
			
			.box3 {
				width: 203px;
				height: 203px;
				border-radius: 50%;
				position: absolute;
				top: 526px;
				left: 958px;
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;
				font-size: 22px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 34px;
				z-index: 1001;
				
				.text14 {
					font-size: 42px;
					font-family: AlibabaPuHuiTi_2_75_SemiBold;
					font-weight: normal;
					color: #FF7800;
					line-height: 42px;
				}
			}
			
			.box4 {
				width: 203px;
				height: 203px;
				background: #88ABDA;
				border: 1px solid #88ABDA;
				opacity: 0.3;
				border-radius: 50%;
				position: absolute;
				top: 526px;
				left: 958px;
			}
			
			.menubox {
				width: 1500px;
				height: 618px;
				box-shadow: 0px 8px 46px 8px rgba(54,54,54,0.5);
				position: absolute;
				top: 270px;
				left: 210px;
				
				.el-menu {
					width: 200px;
					height: 543px;
					margin: 38px 0 37px 0;
					background: none;
					opacity: 0.9;
				}
				
				.el-menu-item:hover {
					background-color: #AAA;
				}
				
				.el-menu-item {
					height: 95px;
					width: 199px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 26px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #FFFFFF;
					line-height: 36px;
					border-top: 1px #1F3F5D solid;
					border-bottom: 1px #1F3F5D solid;
				}
				
				.el-menu-item.is-active {
					width: 211px;
					margin-left: -12px;
					font-size: 30px;
					font-family: AlibabaPuHuiTi_2_75_SemiBold;
					font-weight: normal;
					color: #FFFFFF;
					line-height: 36px;
					opacity: 1;
				}
				
				.el-menu-item:nth-child(1) {
					margin-top: 21px;
				}
				
				.el-menu-item.is-active:nth-child(1) {
					background-color: #1F3F5D;
				}
				.el-menu-item.is-active:nth-child(2) {
					background-color: #533716;
				}
				.el-menu-item.is-active:nth-child(3) {
					background-color: #0F4E89;
				}
			}
		}
	
		.block4 {
			width: 1920px;
			height: 404px;
			background: #FF0000;
			position: absolute;
			top: 2294px;
			
			.text15 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				top: 100px;
				left: 538px;
			}
			
			.text16 {
				font-size: 22px;
				font-family: Alibaba PuHuiTi 2.0;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 30px;
				position: absolute;
				display: flex;
				justify-content: center;
				align-content: center;
			}
			
			.block4button {
				width: 200px;
				height: 60px;
				background: #FFFFFF;
				border-radius: 27px;
				position: absolute;
				top: 278px;
				left: 875px;
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FF0000;
				line-height: 27px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			
			.block4button:hover {
				width: 450px;
				height: 62px;
				left: 735px;
				color: #FFFFFF;
				background: linear-gradient(0deg, #FF5400 0%, #FFCA6F 100%);
				box-shadow: 4px 2px 7px 1px rgba(54,54,54,0.5);
				border-radius: 30px;
			}
		}
	
		.block5 {
			width: 1920px;
			height: 900px;
			background: #FFF2F2;
			position: absolute;
			top: 2697px;
			
			.text17 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				line-height: 36px;
				position: absolute;
				top: 103px;
				left: 665px;
			}
			
			.text18 {
				font-size: 30px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 48px;
				position: absolute;
				top: 171px;
				left: 785px;
			}
			
			.block5box:hover {
				width: 480px;
				height: 435px;
				background: #FFFFFF;
				border-radius: 20px;
				position: absolute;
				top: 257px;
				box-shadow: 0px 8px 46px 8px rgba(54,54,54,0.06);
				
				.boxtop {
					background-color: #FF0000;
					
					.text19 {
						font-size: 48px;
						font-family: AlibabaPuHuiTi_2_75_SemiBold;
						font-weight: normal;
						color: #FFFFFF;
						line-height: 48px;
						position: absolute;
						top: 33px;
						left: 44px;
					}
					
					.text20 {
						font-size: 30px;
						font-family: AlibabaPuHuiTi_2_75_SemiBold;
						font-weight: normal;
						color: #FFFFFF;
						line-height: 48px;
						position: absolute;
						top: 35px;
						left: 108px;
					}
				}
				
				.line {
					width: 480px; 
					height: 1px; 
					background: #FF0000; 
					margin-left: 0px; 
					z-index: 1001;
				}
			}
			
			.block5box {
				width: 480px;
				height: 435px;
				background: #FFFFFF;
				border-radius: 20px;
				position: absolute;
				top: 257px;
				
				.text19 {
					font-size: 48px;
					font-family: AlibabaPuHuiTi_2_75_SemiBold;
					font-weight: normal;
					color: #FF0000;
					line-height: 48px;
					position: absolute;
					top: 33px;
					left: 44px;
				}
				
				.text20 {
					font-size: 30px;
					font-family: AlibabaPuHuiTi_2_75_SemiBold;
					font-weight: normal;
					color: #FF0000;
					line-height: 48px;
					position: absolute;
					top: 35px;
					left: 108px;
				}
				
				.text21 {
					font-size: 18px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #000000;
					line-height: 30px;
					position: absolute;
					top: 140px;
					left: 35px;
				}
				
				.text22 {
					font-size: 20px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #000000;
					line-height: 30px;
					position: absolute;
					top: 229px;
					left: 35px;
				}
				
				.text23 {
					font-size: 20px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #000000;
					line-height: 30px;
					position: absolute;
					top: 309px;
					left: 35px;
				}
			}
			
			.line {
				width: 430px; 
				height: 1px; 
				background: #727272; 
				margin-left: 25px; 
				z-index: 1001;
			}
			
			.block5button {
				width: 200px;
				height: 60px;
				background: #FFFFFF;
				border-radius: 27px;
				position: absolute;
				top: 756px;
				left: 855px;
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				background: #FF0000;
				color: #FFFFFF;
				border-radius: 30px;
				line-height: 27px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			
			.block5button:hover {
				width: 450px;
				height: 60px;
				background: #FF0000;
				box-shadow: 4px 2px 7px 1px #DEE0E7;
				border-radius: 30px;
				left: 735px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FFFFFF;
			}
		}
		
		.block6 {
			width: 1920px;
			height: 762px;
			background: #FAFCFC;
			position: absolute;
			top: 3597px;
			
			.text24 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				line-height: 48px;
				position: absolute;
				top: 108px;
				left: 575px;
			}
			
			.text25 {
				font-size: 24px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #000000;
				line-height: 30px;
				position: absolute;
				top: 145px;
				left: 328px;
			}
			
			.text26 {
				font-size: 18px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #868686;
				line-height: 30px;
				position: absolute;
				top: 182px;
				left: 328px;
			}
			
			.text27 {
				font-size: 22px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 36px;
				position: absolute;
				top: 65px;
				left: 604px;
			}
			
			::v-deep .el-carousel__button {
				width: 18px;
				height: 18px;
				background-color: #FFFFFF;
				border-radius: 50%;
				margin-right: 21px;
				border: 1px solid #FF0000;
			}
			
			::v-deep .el-carousel__indicator.is-active button {
				background-color: #FF0000;
			}
		}
	}
</style>